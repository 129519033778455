var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.fieldset ? 'fieldset' : 'div',{tag:"component",staticClass:"slds-form-element",class:{
    'slds-form-element_stacked': _vm.stacked,
    'slds-form-element_horizontal': _vm.horizontal,
    'slds-has-error': _vm.invalid,
    'slds-has-warning': _vm.warning,
  }},[(_vm.fieldset && _vm.legend)?_c('legend',{staticClass:"slds-form-element__legend slds-form-element__label"},[_vm._v(" "+_vm._s(_vm.legend)+" ")]):_c(_vm.pseudo ? 'span' : 'label',{tag:"component",class:[
      'slds-form-element__label',
      { 'slds-assistive-text': _vm.hideLabel } ],attrs:{"for":this['for']}},[(_vm.required)?_c('abbr',{staticClass:"slds-required",attrs:{"title":"required"}},[_vm._v("* ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("help-text"),_c('div',{staticClass:"slds-form-element__control"},[_vm._t("default")],2),(_vm.error || _vm.hasSlot('help'))?_c('div',{staticClass:"slds-form-element__help"},[_vm._t("help",function(){return [_vm._v(_vm._s(_vm.error))]})],2):_vm._e(),_vm._t("append")],2)}
var staticRenderFns = []

export { render, staticRenderFns }