import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsMedia',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, slots, scopedSlots, data }) {
    const { figure, body } = slots();
    return h(props.tag, mergeData(data, { staticClass: 'slds-media' }), [
      h(
        'div',
        { staticClass: 'slds-media__figure' },
        figure ?? scopedSlots.figure(null)
      ),
      h(
        'div',
        { staticClass: 'slds-media__body' },
        body ?? scopedSlots.body(null)
      ),
    ]);
  },
});
