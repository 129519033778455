<template>
  <SldsFormElement
    :label="label"
    :no-label="hideLabel"
    :error="error"
    :invalid="Boolean(error)"
    :warning="warning"
    class="bc-combobox"
  >
    <div class="slds-combobox_container">
      <div
        ref="root"
        :class="[
          'slds-combobox',
          'slds-dropdown-trigger',
          'slds-dropdown-trigger_click',
          { 'slds-is-open': isOpen },
        ]"
        :aria-expanded="isOpen.toString()"
        aria-haspopup="listbox"
        role="combobox"
      >
        <div
          class="slds-combobox__form-element slds-input-has-icon"
          role="none"
          :class="{
            'slds-input-has-icon_group-right': $clearable,
            'slds-input-has-icon_right': $clearable === false,
          }"
        >
          <input
            ref="input"
            type="text"
            :value="inputValue"
            :placeholder="inputPlaceholder"
            :disabled="disabled"
            :class="[
              'slds-input',
              'slds-combobox__input',
              'slds-combobox__input-value',
              { 'slds-has-focus': isOpen },
            ]"
            autocomplete="off"
            role="textbox"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
          />
          <div
            v-if="$clearable"
            class="slds-input__icon-group slds-input__icon-group_right"
          >
            <SldsButton
              variant="icon"
              icon-name="close"
              title="Remove selected option"
              class="slds-input__icon slds-input__icon_right"
              :disabled="disabled"
              @click="clear"
            />
          </div>
          <slds-input-icon v-else :variant="lookup ? 'lookup' : 'combobox'" />
          <SldsSpinner
            v-if="querying"
            color="brand"
            size="x-small"
            style="right: 1.5rem; left: auto"
          />
        </div>
        <div
          ref="listbox"
          class="slds-dropdown slds-dropdown_length-5 slds-dropdown_fluid"
          role="listbox"
          @scroll.passive="onScroll"
        >
          <ul
            ref="list"
            class="slds-listbox slds-listbox_vertical"
            role="presentation"
          >
            <li
              v-for="(item, index) in options"
              :key="item[optionValue]"
              role="presentation"
              class="slds-listbox__item"
              @mousedown.prevent
              @click="isDisabled(item) ? null : onItemClick(item)"
            >
              <div
                :id="getOptionId(index)"
                :aria-disabled="isDisabled(item) ? 'true' : undefined"
                :class="[
                  'slds-media',
                  'slds-listbox__option',
                  'slds-media_small',
                  {
                    [`slds-media_${media}`]: media,
                    'slds-listbox__option_plain': !hasMeta(item),
                    'slds-listbox__option_entity': hasMeta(item) || isEntity,
                    'slds-listbox__option_has-meta': hasMeta(item),
                    'slds-is-selected':
                      isSelected(item) && isDisabled(item) === false,
                  },
                ]"
                role="option"
              >
                <span class="slds-media__figure slds-listbox__option-icon">
                  <slot name="media" :item="item">
                    <SldsIcon
                      v-if="optionIcon !== null"
                      :type="optionIcon.type"
                      :name="optionIcon.name"
                      size="small"
                    />
                    <SldsIcon
                      v-else-if="isSelected(item)"
                      name="check"
                      size="x-small"
                      class="slds-current-color"
                    />
                  </slot>
                </span>
                <span class="slds-media__body">
                  <slot name="item" :item="item">
                    <template v-if="hasMeta(item)">
                      <span
                        class="slds-listbox__option-text"
                        :title="getItemTitle(item)"
                        >{{ item[optionLabel] }}</span
                      >
                      <span class="slds-listbox__option-meta">{{
                        item[optionMeta]
                      }}</span>
                    </template>
                    <span
                      v-else
                      :class="{ 'slds-truncate': optionTruncate }"
                      :title="getItemTitle(item)"
                      >{{ item[optionLabel] }}</span
                    >
                  </slot>
                </span>
              </div>
            </li>
            <li
              v-if="isNoMatchesVisible"
              role="presentation"
              class="slds-listbox__item"
            >
              <div
                class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small"
              >
                <span class="slds-media__body">
                  <span>No matches</span>
                </span>
              </div>
            </li>
            <li v-if="creational && options.length === 0 && query" key="create">
              <div
                role="option"
                class="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small"
                @mousedown.prevent
                @click="createItem"
              >
                {{ labels.create }} "{{ query }}"
              </div>
            </li>
            <li
              v-if="loading"
              key="loading"
              role="presentation"
              class="slds-listbox__item"
            >
              <div class="slds-align_absolute-center slds-p-top_medium">
                <SldsSpinner inline size="x-small" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="!hideSelected && chosen.length > 0 && single === false"
      class="slds-listbox_selection-group"
    >
      <ul
        class="slds-listbox slds-listbox_horizontal"
        role="listbox"
        aria-label="Selected Options:"
        aria-orientation="horizontal"
      >
        <li
          v-for="item in chosen"
          :key="item[optionValue]"
          class="slds-listbox-item"
          role="presentation"
        >
          <slot name="pill" :item="item" :attrs="pillAttrs" :remove="onRemove">
            <span v-bind="pillAttrs" class="slds-pill">
              <SldsIcon
                v-if="optionIcon !== null"
                :type="optionIcon.type"
                :name="optionIcon.name"
                class="slds-pill__icon_container"
              />
              <span class="slds-pill__label" :title="item[optionLabel]">{{
                item[optionLabel]
              }}</span>
              <SldsIcon
                v-if="disabled === false"
                name="close"
                title="Remove"
                class="slds-pill__remove"
                @click="onRemove(item)"
              />
            </span>
          </slot>
        </li>
      </ul>
    </div>
    <template v-if="hasSlot('help')" #help><slot name="help" /></template>
  </SldsFormElement>
</template>

<script lang="ts">
import Vue from 'vue';
import type { PropType } from 'vue';
import { WithRefs } from 'vue-typed-refs';
import { debounce } from 'lodash-es';
import { createPopper } from '@popperjs/core';

import { generateId, hasSlot, isScrolledToBottom } from '@/util';

import { IconType } from '../Icon/Icon.types';
import SldsIcon from '../Icon/Icon';
import SldsButton from '../Button.vue';
import SldsSpinner from '../Spinner/Spinner';
import SldsFormElement from '../FormElement/FormElement';
import type {
  ComboBoxItem,
  ComoboboxOptionIcon,
  ComboBoxCreateEventPayload,
  ComboBoxDisabledFn,
} from './ComboBox.types';

type Refs = {
  root: HTMLElement;
  input: HTMLInputElement;
  listbox: HTMLElement;
  list: HTMLElement;
};

export default (Vue as WithRefs<Refs>).extend({
  name: 'SldsComboBox',
  components: {
    SldsIcon,
    SldsButton,
    SldsSpinner,
    SldsFormElement,
  },
  props: {
    uid: {
      type: String,
      default() {
        return generateId();
      },
    },
    lookup: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String, Set] as PropType<unknown[] | string | Set<unknown>>,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    items: {
      type: Array as PropType<ComboBoxItem[]>,
      required: true,
    },
    selected: {
      type: Array as PropType<ComboBoxItem[]>,
      default: null,
    },
    single: {
      type: Boolean,
      default: false,
    },
    creational: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    querying: {
      type: Boolean,
      default: false,
    },
    media: {
      type: String,
      default: undefined,
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionMeta: {
      type: String,
      default: 'meta',
    },
    optionIcon: {
      type: Object as PropType<ComoboboxOptionIcon>,
      default: null,
    },
    optionTruncate: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: undefined,
    },
    manualFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledFn: {
      type: Function as PropType<ComboBoxDisabledFn | null>,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default() {
        return {
          create: 'Create',
        };
      },
    },
    defaultMultipleType: {
      type: Function as PropType<ArrayConstructor | SetConstructor>,
      default: Array as ArrayConstructor,
    },
  },
  data() {
    return {
      isOpen: false,
      query: '',
    };
  },
  computed: {
    inputValue(): unknown {
      return this.isOpen ? this.query : this.getInputValue();
    },
    inputPlaceholder(): unknown {
      return this.isOpen === false
        ? this.placeholder ?? 'Select an option'
        : this.getInputPlaceholder();
    },
    options(): ComboBoxItem[] {
      let { items } = this;
      if (this.isEntity) {
        items = items.filter((item) => this.isSelected(item) === false);
      }
      return this.query ? this.filter(items) : items;
    },
    chosen(): ComboBoxItem[] {
      if (this.selected !== null) return this.selected;
      const { value, items, optionValue } = this;
      if (value === null || value === undefined) {
        return [];
      }
      if (typeof value === 'string') {
        const found = items.find((item) => item[optionValue] === value);
        if (found !== undefined) {
          return [found];
        }
        return [];
      }
      return items.filter((item) =>
        Array.isArray(value)
          ? value.includes(item[optionValue])
          : value.has(item[optionValue])
      );
    },
    hasSelected(): boolean {
      return this.value instanceof Set
        ? this.value.size > 0
        : this.value?.length > 0;
    },
    isNoMatchesVisible(): boolean {
      return !this.loading && !this.creational && this.options.length === 0;
    },
    pillAttrs(): Record<string, string> {
      return {
        role: 'option',
        'aria-selected': 'true',
      };
    },
    disabledItems(): Map<ComboBoxItem, true> {
      const { disabledFn } = this;
      if (disabledFn === null) {
        return new Map();
      }
      const disabledItems = this.items.filter((item) => disabledFn(item));
      const map = new Map();
      for (const item of disabledItems) {
        map.set(item, true);
      }
      return map;
    },
    $clearable(): boolean {
      return (
        this.clearable &&
        this.single &&
        this.value !== null &&
        this.value !== undefined &&
        this.value !== ''
      );
    },
    isEntity(): boolean {
      return (
        this.optionIcon !== null && this.optionIcon.type === IconType.Standard
      );
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (this.appendToBody && value) {
        createPopper(this.$refs.root, this.$refs.listbox, {
          strategy: 'fixed',
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 2],
              },
            },
          ],
        });
        this.$refs.listbox.style.width = `${this.$refs.root.clientWidth}px`;
      }
      if (value) {
        this.$nextTick(() => {
          this.$refs.listbox.scrollTop = 0;
        });
      }
    },
    loading(value: boolean) {
      if (value) {
        this.$nextTick(() => {
          const { listbox } = this.$refs;
          listbox.scrollTop = listbox.scrollHeight;
        });
      }
    },
  },
  created() {
    this.onScroll = debounce(this.onScroll, 400);
  },
  methods: {
    hide() {
      this.isOpen = false;
    },
    blur() {
      this.$refs.input.blur();
    },
    isDisabled(item: ComboBoxItem) {
      if (this.disabledItems.size === 0) {
        return false;
      }
      return this.disabledItems.has(item);
    },
    onItemClick(item: ComboBoxItem) {
      const itemValue = this.getItemValue(item);

      if (this.single) {
        this.$emit('input', itemValue);
        this.emitSelect(item);
        this.hide();
        this.blur();
        return;
      }

      const value = [...(this.value ?? [])];

      if (value.includes(itemValue)) {
        const val = value.filter((v) => v !== itemValue);
        this.$emit('input', this.value instanceof Set ? new Set(val) : val);
        this.emitDeselect(item);
      } else {
        const v = [...value, itemValue];
        this.$emit(
          'input',
          this.value instanceof Set || this.defaultMultipleType === Set
            ? new Set(v)
            : v
        );
        this.emitSelect(item);
      }

      if (this.closeOnSelect) {
        this.isOpen = false;
        this.$refs.input.blur();
      }
    },
    onRemove(item: ComboBoxItem) {
      if (typeof this.value === 'string') {
        this.$emit('input', undefined);
      } else {
        const value = this.getItemValue(item);
        this.$emit(
          'input',
          Array.isArray(this.value)
            ? this.value.filter((v) => v !== value)
            : (this.value.delete(value), new Set(this.value))
        );
        this.emitDeselect(item);
      }
    },
    onInput(e: InputEvent) {
      this.query = (e.target as HTMLInputElement).value;
      this.$emit('search', this.query);
    },
    onFocus() {
      this.isOpen = true;
      this.$emit('focus');
    },
    onBlur() {
      this.hide();
      this.$emit('blur');
    },
    onScroll() {
      const isBottom = isScrolledToBottom(this.$refs.listbox);
      if (isBottom) {
        this.$emit('scroll:bottom');
      }
    },
    filter(options: ComboBoxItem[]) {
      if (this.manualFilter) return this.items;
      const lowercasedQuery = this.query.toLowerCase();
      return options.filter((item) => {
        const label = item[this.optionLabel];
        if (typeof label === 'string') {
          return label.toLowerCase().includes(lowercasedQuery);
        }
        return false;
      });
    },
    getOptionId(index: number) {
      return `${this.uid}-${index}`;
    },
    getItemByValue(value: unknown) {
      return this.items.find((item) => item[this.optionValue] === value);
    },
    isSelected(item: ComboBoxItem) {
      if (this.value === null || this.value === undefined) return false;
      if (typeof this.value === 'string') {
        return this.value === item[this.optionValue];
      }
      return Array.isArray(this.value)
        ? this.value.includes(item[this.optionValue])
        : this.value.has(item[this.optionValue]);
    },
    hasMeta(item: ComboBoxItem) {
      return item[this.optionMeta] !== undefined;
    },
    getInputValue() {
      if (typeof this.value === 'string') {
        const item = this.getItemByValue(this.value);
        if (item) {
          return item[this.optionLabel];
        }
        return this.value;
      }
      if (!this.value) return '';
      const length = Array.isArray(this.value)
        ? this.value.length
        : this.value.size;
      if (length === 0) return '';
      return length === 1 ? '1 option selected' : `${length} options selected`;
    },
    getInputPlaceholder(): unknown {
      if (!this.hasSelected) {
        // empty text to invite the user to type something
        return '';
      }
      return this.getInputValue();
    },
    createItem() {
      const payload: ComboBoxCreateEventPayload = { text: this.query.trim() };
      this.$emit('create', payload);
      this.query = '';
      this.$refs.input.blur();
    },
    clear() {
      if (this.single) {
        this.$emit('input', undefined);
      } else {
        this.$emit('input', this.value instanceof Set ? new Set() : []);
      }
    },
    emitSelect(item: ComboBoxItem) {
      this.$emit('select', item, this.getItemValue(item));
    },
    emitDeselect(item: ComboBoxItem) {
      this.$emit('deselect', item, this.getItemValue(item));
    },
    getItemValue(item: ComboBoxItem) {
      return item[this.optionValue];
    },
    getItemTitle(item: ComboBoxItem) {
      return item.title ?? item[this.optionLabel];
    },
    hasSlot,
  },
});
</script>

<style lang="scss">
@use '../../../style/variables';

.bc-combobox {
  .slds-listbox_selection-group {
    height: auto;
  }
}
</style>
