import type { VNode } from 'vue';
import Vue from 'vue';
import type { WithProperties } from 'vue-typed-properties';

import { format, timeout } from './TimeAgo.util';

export default (
  Vue as WithProperties<{ interval?: number; ms?: number }>
).extend({
  name: 'TimeAgo',
  props: {
    since: {
      type: [String, Date],
      default: null,
    },
  },
  data() {
    return {
      formatted: format(this.since),
    };
  },
  computed: {
    date(): Date | null {
      if (this.since === null) return null;
      return new Date(this.since);
    },
    title(): string {
      if (this.date === null) {
        return 'Never';
      }
      return this.date.toLocaleString();
    },
  },
  watch: {
    since(value) {
      this.formatted = format(value);
    },
  },
  created() {
    this.ms = undefined;
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    init() {
      this.formatted = format(this.date);
      this.ms = undefined;
      if (this.date === null) return;
      const ms = timeout(this.date);
      this.startInterval(ms);
    },
    startInterval(ms: number) {
      console.log(ms);
      this.ms = ms;
      this.interval = window.setInterval(() => {
        this.formatted = format(this.date);
        if (this.date === null) return;
        const ms = timeout(this.date);
        if (ms !== this.ms) {
          this.startInterval(ms);
        }
      }, ms);
    },
    stopInterval() {
      if (this.interval !== undefined) {
        window.clearInterval(this.interval);
      }
    },
  },
  render(h): VNode {
    const tag = this.date === null ? 'span' : 'time';
    return h(
      tag,
      {
        attrs:
          this.date !== null
            ? {
                datetime: this.date.toISOString(),
                title: this.title,
              }
            : undefined,
      },
      this.formatted
    );
  },
});
