<template>
  <section
    :id="id"
    class="slds-card"
    :class="{
      'slds-p-top_small': !hasHeader && !noHeaderPadding,
      'slds-card_boundary': boundary,
    }"
  >
    <div
      v-if="hasHeader"
      class="slds-card__header slds-grid"
      :class="headerClass"
    >
      <header class="slds-media slds-media_center slds-has-flexi-truncate">
        <div v-if="hasSlot('icon')" class="slds-media__figure">
          <slot name="icon" />
        </div>
        <div class="slds-media__body">
          <slot name="title" :card="this">
            <component :is="titleTag" v-if="id">
              <router-link
                class="slds-card__header-link slds-truncate"
                :title="title"
                :to="`#${id}`"
              >
                <span class="slds-text-heading_small">{{ title }}</span>
              </router-link>
            </component>
            <component :is="titleTag" v-else>
              <span
                class="slds-card__header-title slds-truncate"
                :title="title"
              >
                {{ title }}
              </span>
            </component>
          </slot>
          <slot name="header" />
        </div>
      </header>
      <div class="slds-no-flex">
        <slot name="buttons" :card="this" />
      </div>
      <slot name="header-append"></slot>
    </div>
    <div
      v-if="loading"
      :class="{ 'slds-spinner_container': loading && noContentHideLoading }"
    >
      <div role="status" class="slds-spinner slds-spinner_small">
        <span class="slds-assistive-text">Loading</span>
        <div class="slds-spinner__dot-a"></div>
        <div class="slds-spinner__dot-b"></div>
      </div>
    </div>
    <div
      v-if="!loading || noContentHideLoading"
      :class="[
        {
          'slds-card__body': true,
          'slds-card__body_inner': hasBodyPadding,
        },
        bodyClass,
      ]"
    >
      <slot />
    </div>
    <footer
      :class="{
        'slds-card__footer': true,
        'slds-p-around_xx-small': noFooterPadding,
      }"
    >
      <slot name="footer" />
    </footer>
  </section>
</template>

<script>
import { hasSlot } from '@/util';

export default {
  name: 'SldsCard',
  props: {
    id: { type: String },
    title: { type: String },
    titleTag: { type: String, default: 'h3' },
    noPadding: { type: Boolean, default: false },
    noContentHideLoading: { type: Boolean, default: false },
    noFooterPadding: { type: Boolean, default: false },
    noHeaderPadding: { type: Boolean, default: false },
    noHeader: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    headerClass: { type: [String, Object, Array] },
    bodyClass: { type: [String, Object, Array] },
    boundary: { type: Boolean, default: false },
  },
  data() {
    return {
      hasIcon: this.$slots.icon && this.$slots.icon.length > 0,
      hasHeader:
        this.noHeader === false &&
        (this.title ||
          (this.$slots.title && this.$slots.title.length > 0) ||
          (this.$slots.buttons && this.$slots.buttons.length > 0)),
      hasBodyPadding: this.noPadding === false,
    };
  },
  methods: {
    hasSlot,
  },
};
</script>
