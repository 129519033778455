<template>
  <component
    :is="fieldset ? 'fieldset' : 'div'"
    class="slds-form-element"
    :class="{
      'slds-form-element_stacked': stacked,
      'slds-form-element_horizontal': horizontal,
      'slds-has-error': invalid,
      'slds-has-warning': warning,
    }"
  >
    <legend
      v-if="fieldset && legend"
      class="slds-form-element__legend slds-form-element__label"
    >
      {{ legend }}
    </legend>
    <component
      :is="pseudo ? 'span' : 'label'"
      v-else
      :class="[
        'slds-form-element__label',
        { 'slds-assistive-text': hideLabel },
      ]"
      :for="this['for']"
    >
      <abbr v-if="required" class="slds-required" title="required">* </abbr>
      {{ label }}
    </component>
    <slot name="help-text" />
    <div class="slds-form-element__control">
      <slot />
    </div>
    <div v-if="error || hasSlot('help')" class="slds-form-element__help">
      <slot name="help">{{ error }}</slot>
    </div>
    <slot name="append" />
  </component>
</template>

<script lang="ts">
import Vue from 'vue';

import { hasSlot } from '@/util';

export default Vue.extend({
  props: {
    for: { type: String },
    label: { type: String },
    required: { type: Boolean },
    stacked: { type: Boolean },
    horizontal: { type: Boolean },
    invalid: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    fieldset: {
      type: Boolean,
      default: false,
    },
    legend: {
      type: String,
    },
    error: {
      type: String,
    },
    pseudo: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hasSlot,
  },
});
</script>

<style lang="scss">
@use '../../style/variables';

.slds-has-warning {
  .slds-input {
    --slds-c-input-color-border: #{variables.$color-border-warning};
    --slds-c-input-shadow: #{variables.$color-border-warning} 0 0 0 #{variables.$border-width-thin}
      inset;
    background-clip: padding-box;

    &:focus,
    &:active {
      --slds-c-input-color-border-focus: #{variables.$color-border-warning};
      --slds-c-input-shadow: #{variables.$color-border-warning} 0 0 0 #{variables.$border-width-thin}
          inset,
        variables.$shadow-button-focus;
    }
  }

  .slds-form-element__help {
    color: variables.$color-text-warning;
  }
}
</style>
